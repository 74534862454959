<template>
    <div class="shop-classify-ecology">
        <!-- 主体 -->
        <CompContent :title="$route.query.labelName + '分类' || '社区生态商城分类'" @on-search="onSearch" @on-reset="onReset">
            <template #operate>
                <Button icon="md-add" type="primary" @click="$refs.data_form.open()">新增</Button>
            </template>
            <template #search="evt">
                <Input v-model="evt.search.labelName" placeholder="分类名称" />
            </template>
            <Table class="table" :height="tableHeight" ref="table" border :columns="columns" row-key="id" :indent-size="20" :data="tableData" @hadlePageSize="onChangeTableSize" @tableSelect="onChangeSelection"></Table>
        </CompContent>

        <DataForm ref="data_form" :classify="tableData" @on-submit="getTableData"></DataForm>
    </div>
</template>

<script>
import CompContent from "../../customform/components/CompContent.vue"
import LiefengTable from "@/components/LiefengTable.vue"

import DataForm from "./form.vue"
import Route from "../../communitymobilization/entity/Route"

export default {
    components: { CompContent, LiefengTable, DataForm },

    data() {
        return {
            columns: [
                {
                    title: "分类名称",
                    key: "labelName",
                    minWidth: 200,
                    align: "center",
                    tree: true,
                },
                {
                    title: "分类图标",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return params.row.icon
                            ? h("img", {
                                  style: {
                                      width: "30px",
                                      height: "30px",
                                  },
                                  attrs: {
                                      src: params.row.icon,
                                  },
                              })
                            : h("p", null, "-")
                    },
                },
                {
                    title: "商家数",
                    key: "companyCount",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "商品数",
                    key: "goodsCount",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "排序",
                    key: "sort",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "操作",
                    minWidth: 200,
                    fixed: "right",
                    align: "center",
                    render: (h, params) => {
                        const arr = [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 3px",
                                    },
                                    on: {
                                        click: () => {
                                            this.$refs.data_form.open(params.row.id)
                                        },
                                    },
                                },
                                "编辑"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 3px",
                                    },
                                    on: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: "温馨提示",
                                                content: "确定要删除该分类吗？",
                                                onOk: () => {
                                                    this.$post(`/gateway/api/sycompany/pc/label/deleteById?labelById=${params.row.id}`).then(res => {
                                                        if (res.code == 200) {
                                                            this.$Message.success("删除成功！")
                                                            this.getTableData()
                                                        }
                                                    })
                                                },
                                            })
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ]

                        if (!params.row.children?.length) {
                            arr.push(
                                h(
                                    "Button",
                                    {
                                        props: {
                                            size: "small",
                                        },
                                        style: {
                                            margin: "0 3px",
                                        },
                                        on: {
                                            click: () => {
                                                Route.jump("/shopclassifyecologycompany", {
                                                    labelId: params.row.id,
                                                })
                                                // this.onDisplayBindCompany(params.row.id)
                                            },
                                        },
                                    },
                                    "商家管理"
                                )
                            )
                        }

                        return h(
                            "div",
                            {
                                style: {
                                    display: "flex",
                                    justifyContent: "center",
                                },
                            },
                            arr
                        )
                    },
                },
            ],
            // 表格数据
            tableData: [],
            // 表格高度
            tableHeight: null,
        }
    },

    created() {
        this.getTableData()
    },

    mounted() {
        window.addEventListener("resize", this.onResize)

        this.$nextTick(() => this.onResize())
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize)
    },

    methods: {
        onResize() {
            this.tableHeight = this.$refs.table.$el.parentNode.clientHeight - 20
        },

        onSearch(evt) {
            this.search = evt.value
            this.getTableData()
        },

        onReset() {
            this.search = null
            this.getTableData()
        },

        getTableData() {
            const load = this.$Message.loading("加载中...")
            this.$get(`/gateway/api/sycompany/pc/label/listLabelToTree?parentId=${this.$route.query.parentId || '4'}`, this.search)
                .then(res => {
                    if (res.code == 200) {
                        function process(arr) {
                            for (let i = 0; i < arr.length; i++) {
                                const item = arr[i]
                                item.label = item.labelName
                                item.value = item.id
                                if (item.children?.length) {
                                    process(item.children)
                                }
                            }

                            return arr
                        }

                        this.tableData = process(res.dataList)
                    } else this.$Message.error(res.desc.slice(0, 50))
                })
                .finally(load)
        },
    },
}
</script>
<style lang="less">
.shop-classify-ecology {
    .table {
        .ivu-table-cell-tree {
            display: inline-block;
        }

        .ivu-table-cell {
            display: flex;

            .ivu-icon {
                font-size: 16px;
                border: 1px solid #e3e3e3;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
}
</style>
